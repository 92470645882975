"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSubTitle = exports.getTitle = exports.getAccordionItems = exports.shopperPayingModes = void 0;
const universal_utils_1 = require("@ecocart/universal-utils");
exports.shopperPayingModes = [
    'customer_paying',
    'customer_matching',
    'merchant_matching',
    'merchant_as_fallback',
    'merchant_as_fallback_with_threshold'
];
const getModalCopies = (template) => {
    switch (template) {
        case 'education_touchpoint':
        case 'ab_test_widget_a_visual':
        case 'ab_test_widget_b_cobranded':
        case 'ab_test_widget_c_action':
        case 'simple_checkout':
        case 'checkout_widget':
            return {
                title: () => (0, universal_utils_1.t)('template_shopSustainablyByOffsetingCO2Footprint'),
                accordion: {
                    common: [],
                    items: {
                        customer_paying: [
                            {
                                title: (merchant) => (merchant === null || merchant === void 0 ? void 0 : merchant.offsetMultiplier) && (merchant === null || merchant === void 0 ? void 0 : merchant.offsetMultiplier) >= 2
                                    ? (0, universal_utils_1.t)('template_yourContributionTwiceAsMuch')
                                    : (0, universal_utils_1.t)('template_byContributingYouWillOffsetCarbon'),
                                content: (merchant) => (merchant === null || merchant === void 0 ? void 0 : merchant.offsetMultiplier) && (merchant === null || merchant === void 0 ? void 0 : merchant.offsetMultiplier) >= 2
                                    ? (0, universal_utils_1.t)('template_ecocartEstimatesEachOrderOffest2x')
                                    : (0, universal_utils_1.t)('template_ecocartEstimatesEachOrdersCarbonFootprint')
                            },
                            {
                                title: () => (0, universal_utils_1.t)('template_carbonTreesCanAbsorb'),
                                content: () => (0, universal_utils_1.t)('template_oneYearTreeAbsorbCO2Amount')
                            },
                            {
                                title: () => (0, universal_utils_1.t)('template_yourContributionWillHelpFund'),
                                content: () => (0, universal_utils_1.t)('template_projectIsAVerifiedProject')
                            }
                        ],
                        merchant_paying: [
                            {
                                title: (merchant) => (merchant === null || merchant === void 0 ? void 0 : merchant.offsetMultiplier) && (merchant === null || merchant === void 0 ? void 0 : merchant.offsetMultiplier) >= 2
                                    ? (0, universal_utils_1.t)('template_merchantWillOffsetTwiceAsMuch')
                                    : (0, universal_utils_1.t)('template_merchantWillOffsetCarbon'),
                                content: (merchant) => (merchant === null || merchant === void 0 ? void 0 : merchant.offsetMultiplier) && (merchant === null || merchant === void 0 ? void 0 : merchant.offsetMultiplier) >= 2
                                    ? (0, universal_utils_1.t)('template_ecocartEstimatesEachOrderOffest2x')
                                    : (0, universal_utils_1.t)('template_ecocartProprietaryAlgo')
                            },
                            {
                                title: () => (0, universal_utils_1.t)('template_carbonTreesCanAbsorb'),
                                content: () => (0, universal_utils_1.t)('template_oneYearTreeAbsorbCO2Amount')
                            },
                            {
                                title: () => (0, universal_utils_1.t)('template_merchantContributionWillHelpFund'),
                                content: () => (0, universal_utils_1.t)('template_projectIsAVerifiedProject')
                            }
                        ],
                        merchant_matching: [
                            {
                                title: (merchant) => (merchant === null || merchant === void 0 ? void 0 : merchant.offsetMultiplier) && (merchant === null || merchant === void 0 ? void 0 : merchant.offsetMultiplier) >= 2
                                    ? (0, universal_utils_1.t)('template_merchantWillOffsetTwiceAsMuch')
                                    : (0, universal_utils_1.t)('template_byContributingYouWillOffsetCarbon'),
                                content: (merchant) => (merchant === null || merchant === void 0 ? void 0 : merchant.offsetMultiplier) && (merchant === null || merchant === void 0 ? void 0 : merchant.offsetMultiplier) >= 2
                                    ? (0, universal_utils_1.t)('template_ecocartEstimatesEachOrderOffest2x')
                                    : (0, universal_utils_1.t)('template_ecocartProprietaryAlgo')
                            },
                            {
                                title: () => (0, universal_utils_1.t)('template_carbonTreesCanAbsorb'),
                                content: () => (0, universal_utils_1.t)('template_oneYearTreeAbsorbCO2Amount')
                            },
                            {
                                title: (merchant) => (merchant === null || merchant === void 0 ? void 0 : merchant.offsetMultiplier) && (merchant === null || merchant === void 0 ? void 0 : merchant.offsetMultiplier) >= 2
                                    ? (0, universal_utils_1.t)('template_merchantContributionWillHelpFund')
                                    : (0, universal_utils_1.t)('template_yourContributionWillHelpFund'),
                                content: () => (0, universal_utils_1.t)('template_projectIsAVerifiedProject')
                            },
                            {
                                title: (merchant) => (merchant === null || merchant === void 0 ? void 0 : merchant.offsetMultiplier) && (merchant === null || merchant === void 0 ? void 0 : merchant.offsetMultiplier) >= 2
                                    ? (0, universal_utils_1.t)('template_matchTheirContributionToQuadruple')
                                    : (0, universal_utils_1.t)('template_merchantMatchYourContribution'),
                                content: (merchant) => (merchant === null || merchant === void 0 ? void 0 : merchant.offsetMultiplier) && (merchant === null || merchant === void 0 ? void 0 : merchant.offsetMultiplier) >= 2
                                    ? (0, universal_utils_1.t)('template_byContributingYourOrderWillOffset4x')
                                    : (0, universal_utils_1.t)('template_yourOrderWillOffsetAtLeastCarbon')
                            }
                        ],
                        customer_matching: [
                            {
                                title: (merchant) => (merchant === null || merchant === void 0 ? void 0 : merchant.offsetMultiplier) && (merchant === null || merchant === void 0 ? void 0 : merchant.offsetMultiplier) >= 2
                                    ? (0, universal_utils_1.t)('template_yourContributionTwiceAsMuch')
                                    : (0, universal_utils_1.t)('template_merchantWillOffsetCarbon'),
                                content: (merchant) => (merchant === null || merchant === void 0 ? void 0 : merchant.offsetMultiplier) && (merchant === null || merchant === void 0 ? void 0 : merchant.offsetMultiplier) >= 2
                                    ? (0, universal_utils_1.t)('template_ecocartEstimatesEachOrderOffest2x')
                                    : (0, universal_utils_1.t)('template_ecocartEstimatesEachOrdersCarbonFootprint')
                            },
                            {
                                title: () => (0, universal_utils_1.t)('template_carbonTreesCanAbsorb'),
                                content: () => (0, universal_utils_1.t)('template_oneYearTreeAbsorbCO2Amount')
                            },
                            {
                                title: () => (0, universal_utils_1.t)('template_merchantContributionWillHelpFund'),
                                content: () => (0, universal_utils_1.t)('template_projectIsAVerifiedProject')
                            },
                            {
                                title: (merchant) => (merchant === null || merchant === void 0 ? void 0 : merchant.offsetMultiplier) && (merchant === null || merchant === void 0 ? void 0 : merchant.offsetMultiplier) >= 2
                                    ? (0, universal_utils_1.t)('template_merchantWillMatchQuadrupling')
                                    : (0, universal_utils_1.t)('template_doubleTheImpact'),
                                content: (merchant) => (merchant === null || merchant === void 0 ? void 0 : merchant.offsetMultiplier) && (merchant === null || merchant === void 0 ? void 0 : merchant.offsetMultiplier) >= 2
                                    ? (0, universal_utils_1.t)('template_yourOrderWillOffset4x')
                                    : (0, universal_utils_1.t)('template_yourOrderWillOffsetAtLeastCarbon')
                            }
                        ],
                        merchant_as_fallback: [
                            {
                                title: (merchant) => (merchant === null || merchant === void 0 ? void 0 : merchant.offsetMultiplier) && (merchant === null || merchant === void 0 ? void 0 : merchant.offsetMultiplier) >= 2
                                    ? (0, universal_utils_1.t)('template_yourContributionTwiceAsMuch')
                                    : (0, universal_utils_1.t)('template_byContributingYouWillOffsetCarbon'),
                                content: (merchant) => (merchant === null || merchant === void 0 ? void 0 : merchant.offsetMultiplier) && (merchant === null || merchant === void 0 ? void 0 : merchant.offsetMultiplier) >= 2
                                    ? (0, universal_utils_1.t)('template_ecocartEstimatesEachOrderOffest2x')
                                    : (0, universal_utils_1.t)('template_ecocartEstimatesEachOrdersCarbonFootprint')
                            },
                            {
                                title: () => (0, universal_utils_1.t)('template_carbonTreesCanAbsorb'),
                                content: () => (0, universal_utils_1.t)('template_oneYearTreeAbsorbCO2Amount')
                            },
                            {
                                title: () => (0, universal_utils_1.t)('template_yourContributionWillHelpFund'),
                                content: () => (0, universal_utils_1.t)('template_projectIsAVerifiedProject')
                            }
                        ],
                        merchant_as_fallback_with_threshold: [
                            {
                                title: (merchant) => (merchant === null || merchant === void 0 ? void 0 : merchant.offsetMultiplier) && (merchant === null || merchant === void 0 ? void 0 : merchant.offsetMultiplier) >= 2
                                    ? (0, universal_utils_1.t)('template_yourContributionTwiceAsMuch')
                                    : (0, universal_utils_1.t)('template_byContributingYouWillOffsetCarbon'),
                                content: (merchant) => (merchant === null || merchant === void 0 ? void 0 : merchant.offsetMultiplier) && (merchant === null || merchant === void 0 ? void 0 : merchant.offsetMultiplier) >= 2
                                    ? (0, universal_utils_1.t)('template_ecocartEstimatesEachOrderOffest2x')
                                    : (0, universal_utils_1.t)('template_ecocartEstimatesEachOrdersCarbonFootprint')
                            },
                            {
                                title: () => (0, universal_utils_1.t)('template_carbonTreesCanAbsorb'),
                                content: () => (0, universal_utils_1.t)('template_oneYearTreeAbsorbCO2Amount')
                            },
                            {
                                title: () => (0, universal_utils_1.t)('template_yourContributionWillHelpFund'),
                                content: () => (0, universal_utils_1.t)('template_projectIsAVerifiedProject')
                            }
                        ]
                    }
                }
            };
        case 'eco_incentives':
        case 'promo':
            return {
                title: () => (0, universal_utils_1.t)('template_shopSustainablyByOffsetingCO2Footprint'),
                subTitle: () => (0, universal_utils_1.t)('template_throughCollectiveActionYouAreTakingProactive'),
                accordion: {
                    common: [
                        {
                            title: () => (0, universal_utils_1.t)('template_EcoCartWillCalculateTheFootprint'),
                            content: () => (0, universal_utils_1.t)('template_ecocartEstimatesEachOrdersCarbonFootprintLifeTimeUsage')
                        }
                    ],
                    items: {
                        customer_paying: [
                            {
                                title: () => (0, universal_utils_1.t)('template_offsetYourOrdersCarbonFootPrintByCheckingOutWithEcocart'),
                                content: () => (0, universal_utils_1.t)('template_selectEcoCartInYourCartOrAtCheckout')
                            },
                            {
                                title: () => (0, universal_utils_1.t)('template_yourContributionWillHelpFund'),
                                content: () => (0, universal_utils_1.t)('template_projectIsAVerifiedProject')
                            }
                        ],
                        customer_matching: [
                            {
                                title: () => (0, universal_utils_1.t)('template_offsetYourOrdersCarbonFootPrintByCheckingOutWithEcocart'),
                                content: () => (0, universal_utils_1.t)('template_withASmallContribution')
                            },
                            {
                                title: () => (0, universal_utils_1.t)('template_yourContributionWillGoTowards'),
                                content: () => (0, universal_utils_1.t)('template_projectIsAVerifiedProject')
                            }
                        ],
                        merchant_matching: [
                            {
                                title: () => (0, universal_utils_1.t)('template_offsetYourOrdersCarbonFootPrintByCheckingOutWithEcocart'),
                                content: () => (0, universal_utils_1.t)('template_withASmallContribution')
                            },
                            {
                                title: () => (0, universal_utils_1.t)('template_yourContributionWillGoTowards'),
                                content: () => (0, universal_utils_1.t)('template_projectIsAVerifiedProject')
                            }
                        ],
                        merchant_paying: [
                            {
                                title: () => (0, universal_utils_1.t)('template_merchantWillOffsetYourOrdersCarbonFootprint'),
                                content: () => (0, universal_utils_1.t)('template_merchantWillCoverTheCostOfOffsettingYourOrder')
                            },
                            {
                                title: () => (0, universal_utils_1.t)('template_merchantContributionWillHelpFund'),
                                content: () => (0, universal_utils_1.t)('template_projectIsAVerifiedProject')
                            }
                        ],
                        merchant_as_fallback: [
                            {
                                title: () => (0, universal_utils_1.t)('template_offsetYourOrdersCarbonFootPrintByCheckingOutWithEcocart'),
                                content: () => (0, universal_utils_1.t)('template_selectEcoCartInYourCartOrAtCheckout')
                            },
                            {
                                title: () => (0, universal_utils_1.t)('template_yourContributionWillHelpFund'),
                                content: () => (0, universal_utils_1.t)('template_projectIsAVerifiedProject')
                            }
                        ],
                        merchant_as_fallback_with_threshold: [
                            {
                                title: () => (0, universal_utils_1.t)('template_offsetYourOrdersCarbonFootPrintByCheckingOutWithEcocart'),
                                content: () => (0, universal_utils_1.t)('template_selectEcoCartInYourCartOrAtCheckout')
                            },
                            {
                                title: () => (0, universal_utils_1.t)('template_yourContributionWillHelpFund'),
                                content: () => (0, universal_utils_1.t)('template_projectIsAVerifiedProject')
                            }
                        ]
                    }
                }
            };
        default:
            return undefined;
    }
};
const getAccordionItems = (payorMode, template) => {
    var _a, _b;
    if (!template)
        return [];
    const commonItems = ((_a = getModalCopies(template)) === null || _a === void 0 ? void 0 : _a.accordion.common) || [];
    const otherItems = ((_b = getModalCopies(template)) === null || _b === void 0 ? void 0 : _b.accordion.items[payorMode]) || [];
    return [...commonItems, ...otherItems];
};
exports.getAccordionItems = getAccordionItems;
const getTitle = (template) => { var _a; return (template ? ((_a = getModalCopies(template)) === null || _a === void 0 ? void 0 : _a.title()) || '' : ''); };
exports.getTitle = getTitle;
const getSubTitle = (template) => { var _a, _b; return (template ? ((_b = (_a = getModalCopies(template)) === null || _a === void 0 ? void 0 : _a.subTitle) === null || _b === void 0 ? void 0 : _b.call(_a)) || '' : ''); };
exports.getSubTitle = getSubTitle;
